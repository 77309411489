import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import NoSsr from '@material-ui/core/NoSsr';
import Tooltip from '@material-ui/core/Tooltip';
import ListIcon from '@material-ui/icons/List';
import { withStyles } from '@material-ui/styles';
import Layout from 'components/Layout';
import { navigate } from 'components/Link';
import qs from 'query-string';
import React, { Suspense, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ga, useGDispatch } from 'state/store';
import { useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function (props) {
  const { branchId, date } = qs.parse(props.location.search);
  const gDispatch = useGDispatch();

  useEffect(() => {
    const uDate = new Date(date);
    const currDate = new Date();
    if (uDate > currDate) {
      gDispatch([ga.DATE, currDate]);
    }
  }, [date, gDispatch]);

  return (
    <Layout {...props}>
      <Box>
        <NoSsr>
          <Suspense fallback={<LinearProgress color="secondary" />}>
            {!(branchId > 0) ? (
              <Box m={2}>
                <Typography>
                  This page is only available from a Branch. Please select a branch from the branch
                  dropdown
                </Typography>
              </Box>
            ) : (
              <AuditingDashboard {...props} />
            )}
          </Suspense>
        </NoSsr>
      </Box>
    </Layout>
  );
}

const AuditingDashboard = (props) => {
  const [notif] = useSnackbar();
  const {
    location: { search },
  } = props;
  const { data: salesManData } = useQuery(`auditing_salesman_data${search}`, () =>
    fetch(`/retailer/audit/salesman${search}`)
      .then((res) => res)
      .catch((err) => {
        notif(err, { variant: 'error' });
      })
  );

  return (
    <Grid container>
      {salesManData?.map((salesMan, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
          <SalesmanCard salesMan={salesMan} />
        </Grid>
      ))}
    </Grid>
  );
};

const RatioElementWithTooltip = ({ nD, dD, n, d, xsText }) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          {n}
          <b>{' / '}</b>
          {d}
        </React.Fragment>
      }
      // enterTouchDelay={100}
    >
      <Typography variant="subtitle1">
        {nD} / {dD}
        <Hidden only={['lg', 'md', 'sm', 'xl']}>
          <Typography color="textSecondary">{xsText}</Typography>
        </Hidden>
      </Typography>
    </HtmlTooltip>
  );
};

const SalesmanCard = ({ salesMan }) => {
  return (
    <Box m={1} p={1} borderRadius={4} w={1} boxShadow={2} color="textSecondary">
      <Box minWidth={1 / 1} display="flex">
        <Box minWidth={3 / 4}>
          <Typography variant="h5" color="textSecondary">
            {salesMan.code} {salesMan.name}
          </Typography>
          <Typography color="textSecondary">Beat : {salesMan.beatName}</Typography>
        </Box>
        <Box minWidth={1 / 4} display="flex">
          <RatioElementWithTooltip
            nD={salesMan.closedInBeat}
            dD={salesMan.totalInBeat}
            n="Customers Audited today"
            d="Total customers in Beat"
            xsText="Audited Today"
          />
        </Box>
      </Box>
      <Box mt={2} minWidth={1 / 1} display="flex">
        <Box minWidth={3 / 4}>
          <RatioElementWithTooltip
            nD={salesMan.totalClosed}
            dD={salesMan.total}
            n="Total Unique Customers Audited"
            d="Total Customers"
            xsText="Audited Till Day"
          />
        </Box>
        <Box minWidth={1 / 4}>
          <RatioElementWithTooltip
            nD={salesMan.closedMoreThan1Day}
            dD={salesMan.total}
            n="Total unique customers audited(closed) for more than one day"
            d="Total Customers"
            xsText="Closed"
          />
        </Box>
      </Box>
      <Box mt={1} minWidth={1 / 4} display="flex">
        <Box alignItems="right">
          <Button
            startIcon={<ListIcon />}
            onClick={() => navigate(`/ct/retailers/?salesManId=${salesMan.id}`)}
            color="primary"
          >
            Customers
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
